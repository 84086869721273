import React from 'react';
import type { PageProps } from 'gatsby';
import { Layout } from '../components/Layout';
import { PrivateRoute } from '../components/PrivateRoute';
import { MyAds } from '../modules/AdList';
import { Footer } from '../modules/Footer';
import { Menu } from '../modules/Menu';

interface MyAdsWrapperProps {
  location: string;
}

const MyAdsWrapper = ({ location }: MyAdsWrapperProps) => {
  return (
    <div className="bg-wild-sand">
      <Layout>
        <Menu menuHasTwoColors={false} />
        <MyAds location={location} />
        <Footer />
      </Layout>
    </div>
  );
};

const Ads = ({ ...props }: PageProps) => (
  <PrivateRoute location={props.location.pathname} component={MyAdsWrapper} />
);

export default Ads;
