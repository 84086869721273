import React from 'react';
import cx from 'classnames';
import map from 'lodash/map';
import { AdListItem } from '../../../components/AdListItem';
import { PARTS_NUMBER } from '../../../utils/comparationFilters';
import { AdvertisementStatus, ProfileType } from '../utils/types';
import styles from './adList.module.css';
import { statusApi } from './services/Status';

interface Props {
  data: any;
  adCount: number;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  profileType: number;
  selectedVehicle: number;
  clientStoreId: number;
  reference: string;
}

export const ActiveSectionAd = ({
  data,
  onClick,
  adCount,
  profileType,
  selectedVehicle,
  clientStoreId,
  reference,
}: Props) => {
  const isCompany = profileType === ProfileType.COMPANY;

  const { draft, active, isAdmin, control } = data;

  const adClasses = cx('bg-white ', styles.cardMargin, {
    mt16: !isAdmin,
    'mt40 mt16-m mt16-l': isAdmin,
  });

  const sectionTitleClasses = cx('f14 abbey', styles.sectionTitle);

  if (!active.length) {
    return null;
  }

  const newActiveAds = map(active, (ad, idx) => {
    const { adStatus, adCode, plan, type } = ad;

    const changeStatus = () => {
      const isParts = selectedVehicle === PARTS_NUMBER;
      const numStatus = adStatus === AdvertisementStatus.ENABLE ? 1 : 0;
      const params = {
        status: numStatus ? 0 : 1,
        advertisementId: adCode,
        userId: adCode,
        reference: isParts ? ad.plan : reference,
      };

      if (isCompany && adStatus !== AdvertisementStatus.ENABLE) {
        params.userId = clientStoreId;
      }

      if (isCompany || adStatus === AdvertisementStatus.ENABLE) {
        return statusApi.changeStatus(isParts, params).then((res: any) => {
          if (!res.status || res.status === 404) {
            return control('error', adCode, params.status, plan);
          }

          // if (res.status === 404) {
          //   return storeControl(params.status, reference);
          // }

          // if ((res.status < 200 || res.status >= 300) && control) {
          //   return control('openModal', adCode, params.status, plan);
          // }

          control('done', adCode, params.status, plan);
        });
      }

      control('openModal', adCode, params.status, plan);
    };

    return (
      <AdListItem
        id={adCode}
        key={idx}
        adOptions={ad}
        adStatus={adStatus}
        className={adClasses}
        onClick={onClick}
        isAdmin={isAdmin}
        changeStatus={changeStatus}
        typeAd={type}
      />
    );
  });

  return (
    <>
      <span className={sectionTitleClasses}>
        {active.length}/{adCount - draft.length} anúncios completos
      </span>
      <div className={styles.cardsWrapper}>{newActiveAds}</div>
    </>
  );
};
