import React from 'react';
import cx from 'classnames';
import { Icon } from '../Icons';
import styles from './style.module.css';

interface Props {
  state?: 'Play' | 'Pause';
  onClick?: () => void;
}
export const PlayButton = ({ state = 'Play', onClick }: Props) => {
  const mainContainerClass = cx('pa0 pl8 flex pointer white fw7 f12 bn absolute', styles.button, {
    [styles.playButton]: state === 'Play',
    [styles.pauseButton]: state === 'Pause',
  });
  const icon = state === 'Play' ? 'SvgIconPlay' : 'SvgIconPause';
  const text = state === 'Play' ? 'REATIVAR' : 'PAUSAR';
  return (
    <button onClick={onClick} className={mainContainerClass}>
      <Icon name={icon} className="mr24" height={11} width={11} />
      {text}
    </button>
  );
};
