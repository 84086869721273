import React from 'react';
import cx from 'classnames';
import { OutlineButton } from '../../Button';
import styles from './style.module.css';

interface Props {
  buttonText: string;
  children?: React.ReactNode;
  onClick: any;
}
export const WrapperContent = ({ children, buttonText, onClick }: Props) => {
  const contentWrapperClasses = cx(
    'flex flex-column flex-auto justify-between',
    styles.contentWrapper,
  );
  return (
    <div className="flex items-end">
      <div className="justify-end dn flex-m flex-l">
        <OutlineButton size="small" onClick={onClick}>
          {buttonText}
        </OutlineButton>
      </div>
      <div className={contentWrapperClasses}>{children}</div>
    </div>
  );
};
