import React, { useState } from 'react';
import cx from 'classnames';
import { Button } from '../../../components/Button/';
import styles from './adList.module.css';

interface Props {
  emptySearch: boolean;
  handleClick: () => void;
}
export const AdListEmptyState = ({ emptySearch, handleClick }: Props) => {
  const wrapperClasses = cx('flex flex-column', styles.wrapper);
  const [isLoading, setIsLoading] = useState(false);
  const redirectAd = () => {
    setIsLoading(true);
    handleClick();
  };

  const textStyle = cx('ma0 center', styles.text);
  const text = emptySearch
    ? 'Nada foi encontrado em sua busca'
    : 'Crie seu primeiro anúncio e anuncie para milhares de pessoas.';
  return (
    <div className="w100 flex justify-center mt24 mt32-ns mt40-m mt32-l noPrint">
      <div className={wrapperClasses}>
        <img
          className="w100"
          src={`${process.env.FTP_IMAGENS}images/ad/empty-state.svg`}
          alt="emptystateimage"
        />
        <div className="tc fw4 mt24 mb12 f14 f16-m f16-l lh-copy abbey">
          <p className={textStyle}>{text}</p>
        </div>
        <Button resetClass classButton="fw7 f18" onClick={redirectAd} loading={isLoading}>
          ANUNCIAR
        </Button>
      </div>
    </div>
  );
};
