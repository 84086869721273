import { axiosInstanceWithAuth } from '../../../../services/axios-instance';

interface StatusProps {
  status: number;
  reference?: string;
}
interface ChangeProps extends StatusProps {
  advertisementId: number | string;
}
interface ChangeAllProps extends StatusProps {
  userId: number | string;
}

const getChangesStatusEndpoint = (isPartsRequest: boolean) =>
  isPartsRequest ? '/advertisement/admin/parts/changeStatus' : '/advertisement/admin/changeStatus';

const getChangesAllEndpoint = (isPartsRequest: boolean) =>
  isPartsRequest ? '/user/parts/plan/admin/changeStatus' : '/user/plan/admin/changeStatus';

const getPlanByUserEndpoint = (isPartsRequest: boolean) =>
  isPartsRequest ? '/planUser/parts/getByUser' : '/planUser/getByUser';

export const statusApi = {
  changeStatus: async (isPartsRequest: boolean, params: ChangeProps) => {
    return await axiosInstanceWithAuth
      .put(getChangesStatusEndpoint(isPartsRequest), params)
      .catch(err => err);
  },
  changeAll: async (isPartsRequest: boolean, params: ChangeAllProps) =>
    await axiosInstanceWithAuth
      .put(getChangesAllEndpoint(isPartsRequest), params)
      .catch(err => err),
  getPlanByUserId: async (isPartsRequest: boolean, userId: string) =>
    await axiosInstanceWithAuth
      .get(getPlanByUserEndpoint(isPartsRequest), { params: { userId } })
      .catch(err => err),
};
