import flatten from 'lodash/flatten';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import map from 'lodash/map';
import { formatCurrency } from '../../../utils/formatNumber';

export const formatDataMediaPrint = (data: any) => {
  if (data) {
    const fantasyName = get(data, '[0].user.fantasy_name', '');

    const group = groupBy(data, fantasyName ? 'user.fantasy_name' : 'user.name');

    const store = head(Object.keys(group));

    const formatGroup = map(group, item => {
      return item;
    });

    const formattedData = map(flatten(formatGroup), item => {
      const { id, model, color, plate, mileage, price, optionals, photoUrl } = item;
      const { brandName, name, modelYear, fabricationYear, version } = model;
      return {
        id,
        brand: brandName,
        vehicle: `${name} ${version}`,
        yearModel: `${fabricationYear}/${modelYear}`,
        color,
        licensePlate: plate,
        typeFuel: get(item, 'typeFuel', ''),
        km: mileage,
        value: formatCurrency(price),
        optional: optionals ? optionals.length : 0,
        photo: photoUrl && photoUrl.length > 0 ? 'Sim' : 'Não',
      };
    });

    return [
      {
        store,
        cars: formattedData,
      },
    ];
  }
};
