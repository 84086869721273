import React from 'react';
import { inject, observer } from 'mobx-react';

import { TypeUserStore } from '../Login/components/SignIn/store/user';
import { TypeClientStore } from '../SubscriptionPlans/store/selectedStore';
import { MyAdsDefault } from './pages';

interface Props {
  user?: TypeUserStore;
  selectedStore?: TypeClientStore;
  location: string;
}
@inject('user', 'selectedStore')
@observer
export class MyAds extends React.Component<Props> {
  render() {
    return <MyAdsDefault {...this.props} />;
  }
}
