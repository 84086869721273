import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { navigate, graphql, StaticQuery } from 'gatsby';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _orderBy from 'lodash/orderBy';
import { ActiveStore } from '../../../components/ActivateStore/ActivateStore';
import { OutlineButton } from '../../../components/Button';
import { Container } from '../../../components/Container';
import { Grid } from '../../../components/Grid';
import { GridCell } from '../../../components/GridCell';
import { Icon } from '../../../components/Icons';
import {
  ModalSuccessAndError,
  modalErrorDefault,
  modalSuccesDefault,
} from '../../../components/ModalSuccessAndError';
import { PARTS_NUMBER } from '../../../utils/productType';
import { TypeUserStore } from '../../Login/components/SignIn/store/user';
import { ChoosePlan } from '../../modals/ChoosePlan';
import { TypeClientStore } from '../../SubscriptionPlans/store/selectedStore';
import { AsideMyAds } from '../components';
import { AdList } from '../Listing/AdList';
import { statusApi } from '../Listing/services/Status';
import { AdServicesFilter, AdServiceParams } from '../services/AdServices';
import {
  formatPlanInformation,
  informationEmpty,
  adapterAdList,
  getVehicleTypes,
} from '../utils/formatterAdList';
import { ProfileType } from '../utils/types';
import styles from './MyAds.module.css';

interface Props {
  user?: TypeUserStore;
  selectedStore?: TypeClientStore;
  availablePlans?: any;
  vehicleTypes?: any;
  location: string;
}

const getVehicleTypeByPathParam = (vehicleTypes: any) => {
  const pathParam = location && location.pathname;
  const vehicleType = vehicleTypes.find(({ url }: { url: string }) => _includes(pathParam, url));
  return vehicleType ? Number(vehicleType.value) : null;
};

export const RenderMyAdsDefault = ({
  user,
  selectedStore,
  availablePlans,
  vehicleTypes,
  location,
}: Props) => {
  const [information, setInformation] = useState(informationEmpty);
  const [listLoading, setListLoading] = useState(true);
  const [emptySearch, setEmptySearch] = useState(false);
  const [list, setList] = useState({});
  const [totalListPages, setTotalListPages] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [configModal, setConfigModal] = useState(modalErrorDefault);
  const [successOrErrorOpen, setSuccessOrErrorOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentIdToChange, setCurrentIdToChange] = useState(-1);
  const [plan, setPlan] = useState('');
  const [openByChangeAll, setOpenByChangeAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [adCount, setAdCount] = useState(0);
  const [plans, setPlans] = useState([
    {
      reference: '',
      sort: 0,
      type_plan: false,
      name: '',
      number_days: 0,
      is_unlimited: false,
    },
  ]);
  const [finalDate, setFinalDate] = useState('');
  const vehicleTypeParam = getVehicleTypeByPathParam(vehicleTypes);

  const [selectedVehicle, setSelectedVehicle] = useState(vehicleTypeParam);
  let profile: any;
  let isAdmin = false;

  useEffect(() => {
    const userType = profile.type === ProfileType.PRIVATE_INDIVIDUAL;
    const plansByUserType = _orderBy(
      _filter(availablePlans, planInfo => planInfo.type_plan === userType),
      'sort',
    );
    setPlans(plansByUserType);
  }, [availablePlans]);

  if (selectedStore && selectedStore.clientStore.id) {
    profile = selectedStore.clientStore;
    isAdmin = true;
  } else if (user) {
    profile = user.profile;
  }

  useEffect(() => {
    if (location) {
      const newVehicleType = getVehicleTypeByPathParam(vehicleTypes);
      if (newVehicleType && newVehicleType !== selectedVehicle) {
        setSelectedVehicle(newVehicleType);
      }
    }
  }, [location]);

  const controlSucessOrError = () => setSuccessOrErrorOpen(!successOrErrorOpen);

  const clickOkModalSOE = () => {
    setSuccessOrErrorOpen(!successOrErrorOpen);
    updatePageInfo();
  };

  const handleClick = () => {
    const route =
      vehicleTypeParam === PARTS_NUMBER
        ? '/cadastrar-anuncio/pecas'
        : '/selecionar-tipo-de-anuncio';
    navigate(route);
  };

  const { type, name, fantasyName, urlFrontagePhoto, urlCompanyLogo } = profile;
  const username = type === 1 ? name : fantasyName;

  const getPlanInfo = () => {
    statusApi.getPlanByUserId(selectedVehicle === PARTS_NUMBER, profile.id).then(res => {
      const planInformation = formatPlanInformation(res.data);
      setPlan(planInformation.reference);
      setInformation(planInformation);
    });
  };
  const getAdList = async (params: AdServiceParams, search?: boolean) => {
    setListLoading(true);

    let response;

    if (selectedVehicle === PARTS_NUMBER) {
      response = await AdServicesFilter.getPartServicesFilter(params)
        .then(resp => resp)
        .catch(resp => resp);
    } else {
      response = await AdServicesFilter.getAdlistFilter(params)
        .then(resp => resp)
        .catch(resp => resp);
    }

    const formatNewData = adapterAdList(
      _get(response, 'data'),
      isAdmin,
      control,
      storeControl,
      selectedVehicle,
    );

    if (search && !formatNewData.active.length && !formatNewData.draft.length) {
      setEmptySearch(true);
    }

    setList(formatNewData);
    setListLoading(false);
    setAdCount(response?.headers?.['content-range'] || 0);
    setTotalListPages(response?.headers ? Math.ceil(response.headers['content-range'] / 10) : 0);
  };

  const updatePageInfo = () => {
    const params = {
      page: 0,
      value: '',
      take: 10,
      id: profile.id,
      type: selectedVehicle,
    };

    if (isAdmin && profile.type === ProfileType.COMPANY) {
      getPlanInfo();
    }
    setCurrentPage(0);
    getAdList(params);
  };

  useEffect(() => {
    updatePageInfo();
  }, [selectedVehicle]);

  const storeControl = async (newStatus: number, updatedReference = '') => {
    setOpenByChangeAll(true);

    const reference = plans && plans.length ? plans[0].reference : '';

    const params = {
      status: newStatus,
      reference: plan || reference || updatedReference,
      userId: profile.id,
      advertisementId: profile.id,
      date: finalDate,
    };

    if (newStatus === 1 && !isOpen) {
      return setIsOpen(true);
    }

    setLoading(true);
    setConfigModal(modalErrorDefault);

    await statusApi.changeAll(selectedVehicle === PARTS_NUMBER, params).then((res: any) => {
      if (res.status && res.status >= 200 && res.status < 300) {
        setConfigModal(modalSuccesDefault);
      }
    });

    setFinalDate('');
    setLoading(false);
    setIsOpen(false);
    setSuccessOrErrorOpen(true);
  };

  const control = (
    done: 'done' | 'openModal' | 'changeStatus' | 'error',
    id: number,
    newStatus: number,
    defaultPlan: string,
  ) => {
    setOpenByChangeAll(false);

    if (done === 'done') {
      setConfigModal(modalSuccesDefault);
      return setSuccessOrErrorOpen(true);
    }

    if (done === 'error') {
      setConfigModal(modalErrorDefault);
      return setSuccessOrErrorOpen(true);
    }

    // TODO: O que esse -1 significa? :)
    const paramId = id === -1 ? profile.id : id;

    setPlan(defaultPlan);
    setCurrentIdToChange(paramId);

    if (done === 'changeStatus') {
      const reference = plans && plans.length ? plans[0].reference : '';

      const params = {
        status: newStatus,
        reference: plan || reference,
        userId: id,
        advertisementId: id,
      };

      return changeStatus(params);
    }

    if (done === 'openModal') {
      return setIsOpen(!isOpen);
    }
  };

  const changeStatus = async (params: any) => {
    setCurrentIdToChange(-1);
    setConfigModal(modalErrorDefault);
    setLoading(true);

    await statusApi.changeStatus(selectedVehicle === PARTS_NUMBER, params).then(res => {
      if (res.status && res.status >= 200 && res.status < 300) {
        setConfigModal(modalSuccesDefault);
      }
    });

    setLoading(false);
    setIsOpen(false);
    setSuccessOrErrorOpen(true);
  };

  const pageSpacingClasses = cx('mb72 pt72-m pt72-l', styles.customPaddingTop, {
    'mt32-ns': (type === 1 && isAdmin) || !isAdmin,
  });

  const asideClasses = cx(
    'ml0-ns mr12-ns mr24-m mr24-l bg-white br3 noPrint',
    styles.asideBoxShadow,
  );

  const activeStoreClass = cx('mb24 mb32-ns', styles.mw914);

  return (
    <Container>
      <section className={pageSpacingClasses}>
        <ActiveStore
          information={information}
          className={activeStoreClass}
          click={storeControl}
          render={isAdmin && type === 2}
          data={availablePlans}
          loading={loading && !isOpen}
        />
        <Grid>
          <GridCell className="flex-auto noPrint" width={[12 / 12, 4 / 12, 3 / 12, 3 / 12]}>
            <div className={asideClasses}>
              <AsideMyAds
                name={username}
                urlFrontagePhoto={urlFrontagePhoto}
                urlCompanyLogo={urlCompanyLogo}
                isAdmin={isAdmin}
                selectedVehicle={selectedVehicle}
              />
            </div>
          </GridCell>
          <GridCell width={[12 / 12, 8 / 12, 9 / 12, 9 / 12]}>
            <section className="flex-ns justify-between noPrint">
              <header className="nowrap items-center flex mb24 mb0-ns">
                <Icon name="SvgIconMenu" className="mr8 red" />
                <span className="fw7 f20 f30-m f30-l abbey">MEUS ANÚNCIOS</span>
              </header>
              <div className="w-50-ns w-auto-m w-auto-l noPrint">
                <OutlineButton size="medium" onClick={handleClick}>
                  <Icon name="SvgIconPlus" width={13} height={13} className="mr4" />
                  criar anúncio
                </OutlineButton>
              </div>
            </section>
            <AdList
              listLoading={listLoading}
              emptySearch={emptySearch}
              list={list}
              totalAdNumber={adCount}
              totalListPages={totalListPages}
              clientStoreId={profile.id}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              updateList={getAdList}
              profileType={profile.type}
              selectedVehicle={selectedVehicle}
              handleClickRedirect={handleClick}
              reference={plan || (plans && plans.length ? plans[0].reference : '')}
            />
          </GridCell>
        </Grid>
      </section>
      <ChoosePlan
        isOpen={isOpen}
        control={control}
        data={availablePlans}
        profile={profile}
        currentPlan={plan}
        currentId={currentIdToChange}
        setIsOpen={setIsOpen}
        setPlan={setPlan}
        storeControl={storeControl}
        openByChangeAll={openByChangeAll}
        loading={loading}
        plans={plans}
        setFinalDate={setFinalDate}
        finalDate={finalDate}
      />
      <ModalSuccessAndError
        closeModal={configModal.success ? clickOkModalSOE : controlSucessOrError}
        isOpen={successOrErrorOpen}
        actionBtn={clickOkModalSOE}
        {...configModal}
      />
    </Container>
  );
};

export const MyAdsDefault = ({ ...props }: Props) => {
  const plansQuery = graphql`
    query {
      allDirectusPlano {
        nodes {
          reference
          sort
          type_plan
          name
          number_days
          is_unlimited
        }
      }
      allDirectusPlanosPeca {
        nodes {
          id
          reference
          sort
          type_plan
          name
          number_days
        }
      }
      allDirectusMinhaConta(filter: { minha_conta_item: { eq: "Conta" } }) {
        nodes {
          submenu_minha_conta {
            icon_submenu_minha_conta
            item_submenu_minha_conta
            url_submenu_minha_conta
            item_submenu {
              name
              url
              type
            }
          }
        }
      }
    }
  `;

  const toRender = (data: any) => {
    const { allDirectusPlano, allDirectusPlanosPeca, allDirectusMinhaConta } = data;
    const vehicleTypes = getVehicleTypes(allDirectusMinhaConta.nodes);
    const vehicleType = getVehicleTypeByPathParam(vehicleTypes);
    const plans =
      vehicleType === PARTS_NUMBER && allDirectusPlanosPeca
        ? allDirectusPlanosPeca.nodes
        : allDirectusPlano.nodes;
    return <RenderMyAdsDefault availablePlans={plans} vehicleTypes={vehicleTypes} {...props} />;
  };
  return <StaticQuery query={plansQuery} render={toRender} />;
};
