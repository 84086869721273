import React from 'react';
import cx from 'classnames';
import { GridCell } from '../../GridCell';
import styles from './style.module.css';

interface Props {
  onDelete: any;
  id: any;
}

export const ProcessingFooter = ({ onDelete, id }: Props) => {
  const draftAdFooterClasses = cx('flex lh-copy tc f12 bt pv8 dn-m dn-l', styles.borderBrownGrey);
  const deleteClass = cx('w-50 bl', styles.borderBrownGrey);

  return (
    <div className={draftAdFooterClasses}>
      <GridCell width={[1 / 2, 9 / 12]} />
      <GridCell width={[1 / 2, 3 / 12]} className={deleteClass}>
        <button type="button" className="abbey bb b--abbey fw7" id={id} onClick={onDelete}>
          EXCLUIR
        </button>
      </GridCell>
    </div>
  );
};
