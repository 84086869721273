import React from 'react';
import cx from 'classnames';
import { navigate } from 'gatsby';
import last from 'lodash/last';
import { vehicleTypeNameByNumber } from '../../modules/VehicleRegistration/utils/functions';
import { PARTS_NUMBER } from '../../utils/comparationFilters';
import { plateMask } from '../../utils/mask';
import { IconButton } from '../Button';
import { PlayButtonSmall } from '../PlayButton';
import { AdContent } from './AdContent';
import { AdFooter } from './AdFooter';
import styles from './style.module.css';

interface Options {
  adStatus: string;
  contacts: number;
  createdAt: string;
  statusColor: string;
  thumbUrl?: string;
  title: string;
  value: string;
  views: number;
  year: string;
  adCode: number;
  type: string;
  plate: string;
  wppViews: number;
}

interface Props {
  id: any;
  adStatus: 'processamento' | 'rascunho' | 'ativo' | 'inativo';
  adOptions: Options;
  className?: string;
  isAdmin?: boolean;
  typeAd?: number;
  newPart?: boolean;
  changeStatus?: () => void;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const AdListItem = ({
  id,
  adStatus,
  adOptions,
  className,
  changeStatus,
  isAdmin = false,
  onClick,
  typeAd,
  newPart = true,
}: Props) => {
  const thumbClasses = cx('br3', styles.thumbnail, { pointer: adStatus !== 'rascunho' });

  const { title, thumbUrl, adCode, type, plate } = adOptions;

  const statusCircleClasses = cx(
    'br3 bw1 b--solid mr4',
    {
      'b--blaze-orange': adStatus === 'rascunho',
      [styles.bgBlazeOrangeOpaque]: adStatus === 'rascunho',
      'b--vibrant-green': adStatus === 'ativo',
      [styles.bgGrassGreenOpaque]: adStatus === 'ativo',
      'b--purpley-pink': adStatus === 'inativo' || adStatus === 'processamento',
      [styles.bgPurpleyPinkOpaque]: adStatus === 'inativo' || adStatus === 'processamento',
    },
    styles.statusCircle,
  );
  const statusColor = cx({
    'grass-green': adStatus === 'ativo',
    'purpley-pink': adStatus === 'inativo' || adStatus === 'processamento',
    'blaze-orange': adStatus === 'rascunho',
  });

  const adCardClasses = cx(
    'relative  pl16 pr16 pr4-m pr4-l flex flex-column br3-ns pb8-m pb8-l pt12 pt16-m pt16-l',
    styles.adCardBoxShadow,
    styles.negativeMargin,
    styles.adCardClasses,
    className,
    {
      [styles.bgInactive]: adStatus === 'inativo' || adStatus === 'processamento',
      'bg-white': adStatus !== 'inativo',
    },
  );

  const playButtonClass = cx({
    'dn flex-m flex-l justify-center items-center':
      adStatus === 'ativo' || adStatus === 'inativo' || adStatus === 'processamento',
    dn: adStatus === 'rascunho',
  });

  const titleClass = cx('f13 fw6 abbey f16-m f16-l', { pointer: adStatus !== 'rascunho' });

  const playOrPause = adStatus === 'inativo' || adStatus === 'processamento' ? 'Play' : 'Pause';
  const playButtonSmall = isAdmin && (
    <PlayButtonSmall state={playOrPause} className={playButtonClass} onClick={changeStatus} />
  );
  const adType =
    typeof window !== 'undefined' && last(window.location.pathname.split('/')) === 'pecas'
      ? PARTS_NUMBER
      : undefined;
  const statusText = adStatus === 'processamento' ? 'inativo' : adStatus;
  const typeName = vehicleTypeNameByNumber(parseInt(type, 10));

  const clickReactivate = () => {
    if (parseFloat(type) === PARTS_NUMBER) {
      return navigate(`/planos/pecas/${adCode}`);
    }
    navigate(`/planos/${adCode}`);
  };

  const clickDraft = () => navigate(`/cadastrar-anuncio/${typeName}/${adCode}`);
  const clickEditAd: any =
    adStatus !== 'rascunho'
      ? () => {
          if (adType) {
            navigate(`/editar-anuncio/pecas/${adCode}`);
          } else {
            navigate(`/editar-anuncio/${adCode}`);
          }
        }
      : undefined;

  const url = thumbUrl
    ? `${process.env.FTP_IMAGENS}${thumbUrl}`
    : `${process.env.FTP_IMAGENS}images/home/image-veiculodefault.jpg`;

  const titleAd = plate ? `${title} - ${plateMask(plate)}` : title;

  return (
    <div className={adCardClasses}>
      <div className="flex pb8">
        <img className={thumbClasses} src={url} onClick={clickEditAd} alt={title} title={title} />
        <div className="flex-auto pl12 pl24-m pl24-l lh-copy flex flex-column justify-around">
          <div className="flex justify-between items-center lh-copy">
            <div className={titleClass} onClick={clickEditAd}>
              {titleAd}
            </div>
            <div className="flex items-center justify-end f12 fw7 ttc">
              <div className={statusCircleClasses} />
              <span className={statusColor}> {statusText} </span>
            </div>
          </div>
          <AdContent
            adStatus={adStatus}
            adOptions={adOptions}
            isAdmin={isAdmin}
            clickReactivate={clickReactivate}
            clickDraft={clickDraft}
            typeAd={typeAd}
            newPart={newPart}
          />
        </div>
        <div className="pl24 items-center dn flex-m flex-l">
          <IconButton
            id={id}
            iconName={'SvgIconDelete'}
            iconWidth={26}
            customClass="pointer"
            onClick={onClick}
          />
        </div>
      </div>
      <AdFooter
        id={id}
        adStatus={adStatus}
        adOptions={adOptions}
        changeStatus={changeStatus}
        isAdmin={isAdmin}
        onDelete={onClick}
        clickReactivate={clickReactivate}
        clickDraft={clickDraft}
      />
      {playButtonSmall}
    </div>
  );
};
