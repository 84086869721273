import React from 'react';
import cx from 'classnames';
import map from 'lodash/map';
import { AdListItem } from '../../../components/AdListItem';
import styles from './adList.module.css';

interface Props {
  data: any;
  onClick: any;
}

export const DraftAd = ({ data, onClick }: Props) => {
  const { draft, isAdmin } = data;
  const sectionTitleClasses = cx('f14 abbey', styles.sectionTitle);
  const adClasses = cx('bg-white mt16', styles.cardMargin);

  if (!draft.length) {
    return null;
  }

  const draftAds = map(draft, (ad, idx) => {
    const { adStatus, adCode } = ad;
    return (
      <AdListItem
        id={adCode}
        key={idx}
        adOptions={ad}
        adStatus={adStatus}
        className={adClasses}
        onClick={onClick}
        isAdmin={isAdmin}
      />
    );
  });

  return (
    <>
      <span className={sectionTitleClasses}>Rascunho ({draft.length})</span>
      <div className={styles.cardsWrapper}>{draftAds}</div>
    </>
  );
};
