import React from 'react';
import cx from 'classnames';
import { Icon } from '../Icons';
import styles from './style.module.css';

interface Props {
  state?: 'Play' | 'Pause';
  className?: string;
  onClick?: () => void;
}
export const PlayButtonSmall = ({ state = 'Play', onClick, className }: Props) => {
  const mainContainerClass = cx(
    'br3 pa0 pointer white fw7 f12 bn absolute',
    styles.buttonSmall,
    {
      [styles.playButton]: state === 'Play',
      [styles.pauseButton]: state === 'Pause',
    },
    className,
  );
  const icon = state === 'Play' ? 'SvgIconPlay' : 'SvgIconPause';
  return (
    <button onClick={onClick} className={mainContainerClass}>
      <Icon name={icon} height={16} width={16} />
    </button>
  );
};
