import React from 'react';
import { PlayButton } from '../PlayButton';
import { ActiveFooter } from './Footers/Active';
import { Footer } from './Footers/Footer';
import { ProcessingFooter } from './Footers/Processing';

export interface OptionsType {
  adStatus: string;
  contacts: number;
  createdAt: string;
  statusColor: string;
  thumbUrl?: string;
  title: string;
  value: string;
  views: number;
  year: string;
  adCode: number;
  wppViews: number;
}

interface Props {
  id: any;
  adStatus: 'processamento' | 'ativo' | 'inativo' | 'rascunho';
  adOptions: OptionsType;
  isAdmin: boolean;
  changeStatus?: () => void;
  onDelete: any;
  clickReactivate: () => void;
  clickDraft: () => void;
}

export const AdFooter = ({
  adStatus,
  adOptions,
  changeStatus,
  isAdmin,
  onDelete,
  clickReactivate,
  clickDraft,
  id,
}: Props) => {
  const playOrPause = adStatus === 'inativo' || adStatus === 'processamento' ? 'Play' : 'Pause';
  if (adStatus === 'rascunho') {
    return (
      <Footer
        id={id}
        adStatus={adStatus}
        isAdmin={isAdmin}
        onDelete={onDelete}
        clickReactivate={clickReactivate}
        clickDraft={clickDraft}
      />
    );
  }
  let footer;
  if (adStatus === 'ativo') {
    footer = <ActiveFooter adOptions={adOptions} onDelete={onDelete} id={id} />;
  }

  if (adStatus === 'processamento') {
    footer = <ProcessingFooter onDelete={onDelete} id={id} />;
  }

  if (adStatus === 'inativo') {
    footer = (
      <Footer
        id={id}
        adStatus={adStatus}
        isAdmin={isAdmin}
        onDelete={onDelete}
        clickReactivate={clickReactivate}
        clickDraft={clickDraft}
      />
    );
  }
  const notDraft = (
    <div className="dn-m dn-l">
      {footer}
      {isAdmin && <PlayButton state={playOrPause} onClick={changeStatus} />}
    </div>
  );

  return notDraft;
};
