export const compareTitles = (keyName: any) => {
  switch (keyName) {
    case 'fuelType':
      return 'Combustível';
    case 'transmission':
      return 'Transmissão';
    case 'color':
      return 'Cor';
    case 'ports':
      return 'Portas';
    case 'optionals':
      return 'Opcionais';
    case 'model_year':
      return 'Ano modelo';
    case 'marches':
      return 'Marchas';
    case 'coolingType':
      return 'Tipo de refrigeração';
    case 'matchType':
      return 'Tipo de partida';
    case 'loadCapacity':
      return 'capacidade de carga';
    case 'cargoVehicleType':
      return 'tipo de veiculo de carga';
    case 'axles':
      return 'eixos';
    case 'bodyStyle':
      return 'tipo';
    case 'userType':
      return 'vendedor';
    case '1':
      return 'particular';
    case '2':
      return 'loja';
    case 'particular':
      return '1';
    case 'loja':
      return '2';
    case 'seatsAmount':
      return 'assentos';
    case 'busType':
      return 'tipo de ônibus';
    case 'carros':
      return 'Carros';
    case 'motos':
      return 'Motos';
    case 'carga':
      return 'Carga';
    case 'servicos':
      return 'Serviços';
    case 'onibus':
      return 'Ônibus';
    case 'nautica':
      return 'Náutica';
    case 'agricola':
      return 'Agrícola';
    case 'partType':
      return 'Tipo de peça';
    case 'false':
      return 'Usada';
    case 'true':
      return 'Nova';
    case 'pecas':
      return 'Peças';
    default:
      return keyName;
  }
};

export const compareKeysToUrl = (keyName: string) => {
  switch (keyName) {
    case 'fuelType':
      return 'combustivel';
    case 'transmission':
      return 'transmissao';
    case 'color':
      return 'cor';
    case 'ports':
      return 'portas';
    case 'optionals':
      return 'opcionais';
    case 'modelYear':
      return 'ano_modelo';
    case 'city':
      return 'cidade';
    case 'brand':
      return 'marca';
    case 'model':
      return 'modelo';
    case 'fantasyName':
      return 'loja';
    case 'search':
      return 'pesquisar';
    case 'version':
      return 'versao';
    case 'price':
      return 'preco';
    case 'mileage':
      return 'quilometragem';
    case 'state':
      return 'estado';
    case 'stateVehicle':
      return 'condicao';
    case 'type':
      return 'categoria';
    case 'bodyStyle':
      return 'tipo';
    case 'marches':
      return 'marchas';
    case 'coolingType':
      return 'refrigeracao';
    case 'matchType':
      return 'partida';
    case 'loadCapacity':
      return 'capacidade de carga';
    case 'cargoVehicleType':
      return 'tipo de veiculo de carga';
    case 'axles':
      return 'eixos';
    case 'userType':
      return 'vendedor';
    case 'seatsAmount':
      return 'assentos';
    case 'busType':
      return 'tipo de ônibus';
    case 'modelType':
      return 'categoria da peça';
    case 'newPart':
      return 'condição da peça';
    case 'partType':
      return 'tipo de peça';
    case 'part_categorie':
      return 'categoriaPeca';
    default:
      return keyName;
  }
};

export const compareChips = (keyName: string) => {
  switch (keyName) {
    case 'onibus':
      return 'ônibus';
    case 'pecas':
      return 'peças';
    default:
      return keyName;
  }
};

export const compareUrlToKeys = (keyName: string) => {
  switch (keyName) {
    case 'combustivel':
      return 'fuelType';
    case 'transmissao':
      return 'transmission';
    case 'cor':
      return 'color';
    case 'portas':
      return 'ports';
    case 'opcionais':
      return 'optionals';
    case 'ano_modelo':
      return 'model_year';
    case 'marca':
      return 'brand';
    case 'cidade':
      return 'city';
    case 'modelo':
      return 'model';
    case 'loja':
      return 'fantasyName';
    case 'pesquisar':
      return 'search';
    case 'versao':
      return 'version';
    case 'preco':
      return 'price';
    case 'quilometragem':
      return 'mileage';
    case 'estado':
      return 'state';
    case 'condicao':
      return 'stateVehicle';
    case 'categoria':
      return 'type';
    case 'tipo':
      return 'bodyStyle';
    case 'marchas':
      return 'marches';
    case 'refrigeracao':
      return 'coolingType';
    case 'partida':
      return 'matchType';
    case 'capacidade de carga':
      return 'loadCapacity';
    case 'tipo de veiculo de carga':
      return 'cargoVehicleType';
    case 'eixos':
      return 'axles';
    case 'vendedor':
      return 'userType';
    case 'assentos':
      return 'seatsAmount';
    case 'tipo de ônibus':
      return 'busType';
    default:
      return keyName;
  }
};

export const compareVehicleNumbers = (keyNumber: string) => {
  switch (keyNumber) {
    case '1':
      return 'Carro';
    case '2':
      return 'Motos';
    case '3':
      return 'Ônibus';
    case '4':
      return 'Carga';
    case '5':
      return 'Náutico';
    case '6':
      return 'Agrícola';
  }
};

export const CAR_NUMBER = 1;
export const MOTORCYCLE_NUMBER = 2;
export const BUSS_NUMBER = 3;
export const CARGO_VEHICLE_NUMBER = 4;
export const NAUTICAL_VEHICLE_NUMBER = 5;
export const AGRICULTURAL_VEHICLE_NUMBER = 6;
export const SERVICES_NUMBER = 7;
export const PARTS_NUMBER = 8;
export const STORES_NUMBER = 9;
