import React from 'react';
import map from 'lodash/map';
import { formatDataMediaPrint } from './utils/formatDataMediaPrint';

interface Props {
  data: any;
}

export const MediaPrint = ({ data }: Props) => {
  const newData = formatDataMediaPrint(data);

  const itemBodyTable = (obj: any) => map(obj, item => <td>{item}</td>);

  const bodyTable = (item: any) => {
    const { cars } = item;
    return map(cars, obj => <tr>{itemBodyTable(obj)}</tr>);
  };

  const tableFull = () =>
    map(newData, (item, idx) => (
      <React.Fragment key={`${item.store}${idx}`}>
        <table className="printScreen">
          <thead>
            <tr>
              <th colSpan={9}>{item.store}</th>
              <th colSpan={2}>Qtd Total: {item.cars.length}</th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th>Id</th>
              <th>Marca</th>
              <th>Veiculo</th>
              <th>Ano/Modelo</th>
              <th>Cor</th>
              <th>Placa</th>
              <th>C</th>
              <th>KM</th>
              <th>Valor</th>
              <th>Opcionais</th>
              <th>Possui foto</th>
            </tr>
          </thead>
          <tbody>{bodyTable(item)}</tbody>
          <tbody>
            <tr>
              <td colSpan={11}>
                <table>
                  <thead>
                    <tr>
                      <th align="center" colSpan={11}>
                        Declaro estarem corretas e serem de minha responsabilidade as informações
                        acima citadas
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ width: '40%' }}>Nome</td>
                      <td style={{ width: '40%' }}>Assinatura</td>
                      <td>
                        Data <span>&nbsp;&nbsp;____/____/________</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
      </React.Fragment>
    ));
  return <>{tableFull()}</>;
};
