import React, { useState } from 'react';
import cx from 'classnames';
import map from 'lodash/map';
import moment from 'moment';
import { Button } from '../../../components/Button';
import { Link } from '../../../components/Link';
import { Radio } from '../../../components/Radio';
// Just for build
import { StyledModal } from '../../../components/StyledModal';
import { TextInput } from '../../../components/TextInput';
import { dateMask } from '../../../utils/mask';
import { ProfileType } from '../../AdList/utils/types';
import styles from './style.module.css';

interface Props {
  isOpen: boolean;
  data?: any;
  profile: any;
  currentPlan: string;
  currentId: number;
  setIsOpen: (isOpen: boolean) => void;
  setPlan: (plan: string) => void;
  control: (
    done: 'done' | 'openModal' | 'changeStatus',
    id: number,
    newStatus: number,
    defaultPlan: string,
  ) => void;
  storeControl: (newStatus: number) => void;
  openByChangeAll?: boolean;
  loading?: boolean;
  plans: {
    reference: string;
    sort: number;
    type_plan: boolean;
    name: string;
    number_days: number;
    is_unlimited: boolean;
  }[];
  setFinalDate: (finalDate: string) => void;
  finalDate: string;
}
export const ChoosePlan = ({
  isOpen,
  control,
  currentPlan,
  setPlan,
  setIsOpen,
  currentId,
  openByChangeAll,
  storeControl,
  loading,
  plans,
  setFinalDate,
  profile,
  finalDate,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState('');

  const closeModal = () => setIsOpen(!isOpen);
  const setSelectedPlan = (e: any) => setPlan(e.target.value);
  const setDateInformation = (e: any) => setFinalDate(e?.target?.value);
  const isDateValid = () => errorMessage === '';
  const onClick = () => {
    if (isDateValid()) {
      if (!openByChangeAll) {
        control('changeStatus', currentId, 1, currentPlan);
      } else {
        storeControl(1);
      }
    }
  };
  const radioStyle = cx('pt8 bb pb0', styles.containerRadioClass);
  const radioMain = cx('f16 fw3', styles.radioMain);

  const mapRadio = () =>
    map(plans, (plan, index) => {
      const { is_unlimited, number_days, name, reference } = plan;
      const description = is_unlimited ? 'ilimitado' : `(${number_days} dias)`;
      const planName = `${name} ${description}`;
      const defaultChecked = currentPlan ? currentPlan === reference : index === 0;
      return (
        <div className={radioStyle} key={reference}>
          <Radio
            id={reference}
            value={reference}
            name="choosePlanRadios"
            label={planName}
            tabIndex={0}
            classRadio={radioMain}
            defaultChecked={defaultChecked}
            classSpan
            onChange={setSelectedPlan}
          />
        </div>
      );
    });

  const hrStyle = cx('mv0 bn bg-alto ', styles.hrStyle);

  const validateDate = () => {
    const date = moment(finalDate, 'DD/MM/YYYY', true);

    if ((date.isValid() && !date.isBefore(moment(), 'day')) || finalDate === '') {
      setErrorMessage('');
    } else {
      setErrorMessage('Data Inválida!');
    }
  };

  const renderDateInput = () => {
    if (profile.type === ProfileType.COMPANY) {
      return (
        <div className="mb16">
          <TextInput
            name="date"
            id="dateInput"
            labeltext="Validade do anúncio"
            value={finalDate}
            onChange={setDateInformation}
            mask={dateMask}
            maxLength={10}
            onBlur={validateDate}
            errorMessage={errorMessage}
            required
          />
        </div>
      );
    }

    return null;
  };

  return (
    <StyledModal
      loading={loading}
      isOpen={isOpen}
      closeModal={closeModal}
      className={styles.modalClass}
    >
      <div className="mh16 abbey ">
        <h1 className="f26 fw2 mt32 mb16">Escolha o Plano:</h1>
        <div className={styles.mainForm}>{mapRadio()}</div>

        {renderDateInput()}

        <hr className={hrStyle} />
        <div className="flex justify-center flex-column mh0  mt24">
          <Button loading={loading} classButton="mb24" onClick={onClick}>
            CONCLUIR
          </Button>
          <Link
            disabled={loading}
            resetClass
            onClick={closeModal}
            className="flex justify-center mb24 f14 fw7 torch-red ttu lh-title underline pointer"
          >
            Cancelar
          </Link>
        </div>
      </div>
    </StyledModal>
  );
};
