export enum AdvertisementStatus {
  ENABLE = 'ativo',
  DISABLE = 'inativo',
  PROCESSING = 'processamento',
  DRAFT = 'rascunho',
}

export enum ProfileType {
  PRIVATE_INDIVIDUAL = 1,
  COMPANY = 2,
}
