import React from 'react';
import cx from 'classnames';
import styles from './style.module.css';

interface Props {
  id: any;
  adStatus: 'processamento' | 'ativo' | 'inativo' | 'rascunho';
  isAdmin: boolean;
  onDelete: any;
  clickReactivate: () => void;
  clickDraft: () => void;
}
export const Footer = ({ isAdmin, adStatus, onDelete, clickReactivate, clickDraft, id }: Props) => {
  const draftAdFooterClasses = cx('flex lh-copy tc f12 bt pv8 dn-m dn-l', styles.borderBrownGrey);
  const resumeButtonClasses = cx('w-50 br', styles.borderBrownGrey);
  const button =
    adStatus === 'rascunho' ? (
      <button
        type="button"
        className="bn w-100  bb b--red red fw7 underline pointer bg-transparent"
        onClick={clickDraft}
      >
        RETOMAR
      </button>
    ) : (
      !isAdmin && (
        <button
          className="bn w-100  bb b--red red fw7 underline pointer bg-transparent"
          onClick={clickReactivate}
        >
          REATIVAR ANÚNCIO
        </button>
      )
    );
  return (
    <div className={draftAdFooterClasses}>
      <div className={resumeButtonClasses}>{button}</div>
      <div className="w-50">
        <button
          id={id}
          type="button"
          className="bn w-100 abbey bb b--abbey fw7 pointer underline bg-transparent"
          onClick={onDelete}
        >
          EXCLUIR
        </button>
      </div>
    </div>
  );
};
