import React, { useState } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { Grid } from '../../../components/Grid';
import { GridCell } from '../../../components/GridCell';
import { LinkWithIcons } from '../../../components/Link';
import { Loader } from '../../../components/Loaders';
import { MediaPrint } from '../../../components/MediaPrint';
import { ModalSuccessAndError } from '../../../components/ModalSuccessAndError';
import { Pagination } from '../../../components/Pagination';
import { Search } from '../../../components/Search';
import { PARTS_NUMBER } from '../../../utils/productType';
import { DeleteAdServices } from '../services/AdServices';
import { ActiveSectionAd } from './ActiveSectionAd';
import { AdListEmptyState } from './AdListEmptyState';
import { DraftAd } from './DraftSectionAd';

// import instance from './apiPdf/api';

export interface Ad {
  title: string;
  thumbUrl: string;
  createdAt: string;
  value: string;
  year: string;
  views: number;
  contacts: number;
  adStatus: string;
  statusColor: string;
  adCode: number;
}

export interface PartsAd {
  title: string;
  thumbUrl: string;
  value: string;
  adStatus: string;
  statusColor: string;
  adCode: number;
  plan: string;
  newPart: boolean;
  type: number;
}

export const AdList = inject('selectedStore')(
  observer(
    ({
      updateList,
      listLoading,
      list,
      emptySearch,
      totalListPages,
      clientStoreId,
      currentPage,
      setCurrentPage,
      totalAdNumber,
      profileType,
      selectedVehicle,
      handleClickRedirect,
      reference,
    }: any) => {
      const [openModal, setOpenModal] = useState(false);
      const [originalData] = useState();
      const [valueSearch, setValueSearch] = useState('');
      const [currrentId, setCurrentId] = useState();
      const handleChange = (e: any) => setValueSearch(e.target.value);

      const handleClick = async (data: any) => {
        const { selected } = data;
        setCurrentPage(selected);

        const params = {
          page: selected || 0,
          value: valueSearch,
          take: 10,
          id: clientStoreId,
          type: selectedVehicle,
        };
        await updateList(params, true);
      };

      const onDelete = (e: any) => {
        const adCode = e.currentTarget.id;
        setCurrentId(adCode);
        setOpenModal(!openModal);
      };

      const confirmOnDelete = async () => {
        const params = {
          page: currentPage || 0,
          value: '',
          take: 10,
          id: clientStoreId,
        };

        if (selectedVehicle === PARTS_NUMBER) {
          await DeleteAdServices.deletePardAd(currrentId)
            .then(res => res)
            .catch(err => err);
        } else {
          await DeleteAdServices.deleteAd(currrentId)
            .then(res => res)
            .catch(err => err);
        }

        await updateList(params);
      };

      const closeModal = () => {
        setOpenModal(!openModal);
      };
      const loadingEmptyOrContent = () => {
        if (listLoading) {
          return <Loader />;
        }
        if (!list.active.length && !list.draft.length) {
          return <AdListEmptyState emptySearch={emptySearch} handleClick={handleClickRedirect} />;
        }

        return (
          <>
            <section className="mt12 mt0-ns noPrint">
              <DraftAd data={list} onClick={onDelete} />
            </section>
            <section className="pt32 noPrint">
              <ActiveSectionAd
                data={list}
                adCount={totalAdNumber}
                onClick={onDelete}
                profileType={profileType}
                selectedVehicle={selectedVehicle}
                clientStoreId={clientStoreId}
                reference={reference}
              />
            </section>
            <section className="flex justify-center noPrint">
              <Pagination
                forcePage={currentPage}
                onClick={handleClick}
                totalPages={totalListPages}
              />
            </section>
          </>
        );
      };
      const instance = axios.create({
        baseURL: process.env.REL_GEN_URL,
        headers: { Authorization: 'Bearer ' + window.localStorage.getItem('Authorization') },
      });
      const print = () => {
        const params = {
          page: 0,
          id: clientStoreId,
        };
        instance
          .get('/ads/' + params.id)
          .then(response => {
            const blob = new Blob([response.data], {
              type: 'application/pdf',
            });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
          })
          .catch(error => {
            console.error(error);
          });
      };
      return (
        <>
          <section className="mt24 mb24-m mb24-l noPrint">
            <Grid className="flex flex-column flex-row-m flex-row-l justify-between-m justify-between-l items-end-m items-end-l">
              <GridCell width={[1, 1, 0.5373, 0.54064]}>
                <Search
                  placeholder="Pesquise em seus anúncios"
                  onChange={handleChange}
                  value={valueSearch}
                  onClick={handleClick}
                  aria-label="Pesquise em seus anúncios"
                />
              </GridCell>
              <GridCell width={[1, 1, 4 / 12]}>
                <LinkWithIcons
                  iconLeft="SvgIconPrint"
                  iconLeftClass="red"
                  onClick={print}
                  containter="mt12 mt0-m mt0-l justify-start justify-end-ns"
                  className="f14 fw7 ttu red"
                  action
                  title="Baixar todos anúncios"
                >
                  baixar todos anúncios
                </LinkWithIcons>
              </GridCell>
            </Grid>
          </section>
          {loadingEmptyOrContent()}
          <ModalSuccessAndError
            title="Atenção"
            subTitle="Deseja realmente excluir este anúncio?"
            isOpen={openModal}
            success={false}
            actionBtn={confirmOnDelete}
            closeModal={closeModal}
            actionBtnSecondary={closeModal}
            textBtnClose="Excluir"
            textBtnSecondary="Cancelar"
          />
          <MediaPrint data={originalData} />
        </>
      );
    },
  ),
);
