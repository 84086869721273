import React from 'react';
import { Icon } from '../../Icons';
import { Options } from '../AdContent';

interface Props {
  adOptions: Options;
}
export const ContentProcessing = ({ adOptions }: Props) => {
  const { createdAt, adCode } = adOptions;
  return (
    <div className="flex justify-between">
      <div className="flex pt12-m pt12-l">
        <div className="flex items-center-ns">
          <Icon className="boulder mr4" name="SvgIconHourglass" height={18} width={18} />
        </div>
        <div className="flex items-center">
          <span className="boulder f11 fw4 lh-solid f14-m f14-l">
            Aguardando a confirmação do pagamento.
          </span>
        </div>
      </div>
      <div className="flex flex-column items-end boulder fw4 f11 f12-m f12-l">
        <p className="ma0 dn db-ns fw3 ">
          Criado em
          <span className="fw4"> {createdAt}</span>
        </p>
        <span className="dn-ns"> {createdAt}</span>
        <div> #{adCode} </div>
      </div>
    </div>
  );
};
