import React from 'react';
import cx from 'classnames';
import { find, get } from 'lodash';
import { PrimaryButton } from '../Button/Primary';
import { Icon } from '../Icons';
import styles from './ActiveStore.module.css';

export interface DataType {
  id: string;
  reference: string;
  date: string;
  status: 'ativo' | 'inativo' | 'pendente';
}

interface Props {
  information: DataType;
  click: (newStatus: number) => void;
  className?: string;
  render?: boolean;
  data?: any;
  loading?: boolean;
}

export const ActiveStore = ({
  information,
  click,
  className,
  render = true,
  data,
  loading,
}: Props) => {
  if (!render || !data) {
    return null;
  }

  const { date, status } = information;
  const isActive = status === 'ativo';
  const onClick = () => click(isActive ? 0 : 1);
  const planInfo = find(data, plan => plan.reference === information.reference);
  const numberDays = get(planInfo, 'number_days', 0);
  const name = get(planInfo, 'name', 0);
  const isUnlimited = get(planInfo, 'is_unlimited', 0);
  const description = isUnlimited ? 'ilimitado' : `(${numberDays} dias)`;
  const planName = `${name} ${description}`;
  const textStore = isActive ? 'Pausar Loja' : ' Reativar Loja';
  const iconStore = isActive ? 'SvgIconPause' : 'SvgIconPlay';
  const sectionMain = cx('bg-white flex-ns items-center-ns pv12-ns', styles.sectionMain, className);
  const btnStyle = cx('mt32 mt0-ns', styles.btnStyle);
  const spanText = cx('items-center', styles.spanText);
  const iconStyle = cx('flex items-center absolute h-100 top-0 noPrint', styles.iconStyle);

  return (
    <section className={sectionMain}>
      <div className="mh24 ml12-ns mr24-ns mh32-m white">
        <PrimaryButton
          loading={loading}
          green={!isActive}
          size="medium"
          onClick={onClick}
          classButton={btnStyle}
        >
          <div className="flex items-center">
            <div className={iconStyle}>
              <Icon name={iconStore} width={16} height={16} />
            </div>

            <span className={spanText}>{textStore}</span>
          </div>
        </PrimaryButton>
      </div>
      <div className="abbey ml12 ml0-ns mt32 mt0-ns mb0-ns pb24 pb0-ns lh-text flex-ns noPrint">
        <h2 tabIndex={0} className="f16 fw2 ma0 mr32-ns">
          Planos:
          <p className="pl4 di ma0 fw7">{planName}</p>
        </h2>
        <h2 tabIndex={0} className="f16 fw2 ma0 mr32-ns ">
          Desde:
          <p className="pl4 di ma0 fw7">{date} </p>
        </h2>
        <h2 tabIndex={0} className="f16 fw2 ma0">
          Status:
          <p className="pl4 di ma0 fw7">{status} </p>
        </h2>
      </div>
    </section>
  );
};
