import React from 'react';
import cx from 'classnames';
import { formatCurrency } from '../../../utils/formatNumber';
import { PARTS_NUMBER } from '../../../utils/productType';
import { Options } from '../AdContent';
import styles from './style.module.css';

interface Props {
  adStatus: 'processamento' | 'rascunho' | 'ativo' | 'inativo';
  days?: number;
  adOptions: Options;
  isAdmin: boolean;
  typeAd?: number;
}
export const Content = ({ adOptions, adStatus, days, isAdmin, typeAd }: Props) => {
  const { value, year, createdAt, adCode, views, contacts, newPart, wppViews } = adOptions;

  // const totalContacts = (contacts || 0) + (wppViews || 0);

  // const contactsAds = typeAd === PARTS_NUMBER ? '' : 'Contatos';

  const displayWhenActiveStatus = cx({
    dn:
      (isAdmin && adStatus === 'rascunho') ||
      adStatus === 'processamento' ||
      (!isAdmin && adStatus !== 'ativo'),
  });

  const middleLabels = cx('dn', {
    'db-m db-l': adStatus === 'ativo' || (isAdmin && adStatus === 'inativo'),
  });

  const condition = cx('fw7', {
    dn: typeAd !== PARTS_NUMBER,
  });

  const adInfoRow = cx('flex f11 boulder fw4 f12-m f12-l', styles.infoRow, {
    'justify-between': adStatus === 'ativo',
    'justify-end': adStatus !== 'ativo',
  });

  const created = days ? (
    'Finaliza em {days} dias'
  ) : (
    <>
      Criado em <span className="fw4">{createdAt}</span>
    </>
  );

  const viewsAds = typeAd === PARTS_NUMBER ? '' : 'visualizações';

  const newPartAds = newPart ? 'Novo' : 'Usado';

  return (
    <>
      <div className={adInfoRow}>
        <div className={displayWhenActiveStatus}> {formatCurrency(value)} </div>
        <div className={middleLabels}>
          <span className="fw7"> {views} </span> {viewsAds}
          <span className={condition}>{newPartAds}</span>
        </div>
        <div className="fw3 tr">{typeAd !== PARTS_NUMBER && created}</div>
      </div>
      <div className={adInfoRow}>
        <div className={displayWhenActiveStatus}> {year} </div>
        {/* <div className={middleLabels}>
          <span className="fw7"> {totalContacts} </span> {contactsAds}
        </div> */}
        <div className="tr"> #{adCode} </div>
      </div>
    </>
  );
};
